import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { DescriptionAmenitiesProps } from './description-amenities';

export const mapHomePageDataToDescriptionAmenities = ({
  homepageData,
}: AvailableHomePageData): DescriptionAmenitiesProps => ({
  amenities: homepageData?.amenities,
  brandCode: homepageData?.brandCode,
  headline: homepageData?.facilityOverview?.headline,
  shortDesc: homepageData?.facilityOverview?.shortDesc,
});
