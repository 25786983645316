import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { InspiredHeroProps } from './InspiredHero';

export const mapHomePageDataToInspiredHero = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<InspiredHeroProps> => {
  const images = homepageData?.images?.carousel;

  return images?.length ? { images } : DO_NOT_RENDER;
};
