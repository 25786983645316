import { useCallback, useEffect, useRef } from 'react';
import { useBoolean, useEventListener } from 'usehooks-ts';
import type { VideoPlayerProps, PlayerState, useVideoPlayer } from './use-video-player';

type UseFadeControlsOptions = VideoPlayerProps &
  PlayerState & {
    videoElement: ReturnType<typeof useVideoPlayer>['videoProps']['videoElement'];
  };

const FADE_TIMEOUT_IN_MS = 3000;

/**
 * Hook to manage the visibility of video controls.
 */
export function useFadeControls(props: UseFadeControlsOptions) {
  const { isPlaying, wrapperElement, videoElement } = props;
  const elementRef = (wrapperElement || videoElement) as React.RefObject<HTMLElement>;
  const { value: isVisible, setTrue: setVisible, setFalse: setHidden } = useBoolean(true);
  const timeout = useRef(0);

  const hideControls = useCallback(() => {
    if (isPlaying) {
      setHidden();
    }
    window.clearTimeout(timeout.current);
  }, [setHidden, isPlaying]);

  const showControls = useCallback(() => {
    setVisible();
    window.clearTimeout(timeout.current);
  }, [setVisible]);

  const fadeControls = useCallback(() => {
    if (isPlaying) {
      timeout.current = window.setTimeout(hideControls, FADE_TIMEOUT_IN_MS);
    }
  }, [hideControls, isPlaying]);

  const handleKeyup = useCallback(
    (event: KeyboardEvent) => {
      const isInteracting = elementRef.current?.contains(event.target as HTMLElement);
      isInteracting ? showControls() : hideControls();
    },
    [elementRef, hideControls, showControls]
  );

  useEffect(() => {
    isVisible ? showControls() : fadeControls();
  }, [fadeControls, isVisible, showControls]);

  useEventListener('mousemove', showControls, elementRef);
  useEventListener('mouseleave', hideControls, elementRef);
  useEventListener('keyup', handleKeyup);

  return { isVisible };
}
