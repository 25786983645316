import { DO_NOT_RENDER } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/property-utils';

import type { PatchworkGridType } from '@dx-ui/patchwork-grid';
import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { PatchworkGridsProps } from './PatchworkGrids';

const mapCmsLinkToPatchworkLink = (
  link: NonNullable<
    NonNullable<AvailableHomePageData['homepageData']>['propertyComponents']
  >['patchworkGrid'][number]['links'][number]
) => ({
  label: link.linkLabel,
  url: link.linkUrl,
  isNewWindow: Boolean(link.isNewWindow),
});

const mapCmsImageToPatchworkImage = (
  image: NonNullable<
    NonNullable<
      NonNullable<AvailableHomePageData['homepageData']>['propertyComponents']
    >['patchworkGrid'][number]
  >['images'][number]
) => {
  const { oneByOne, twoByOne } = convertCmsImageRatiosToMap(image.ratios);

  return {
    url: oneByOne ?? '',
    altText: image.altText ?? undefined,
    ...(twoByOne && {
      variants: {
        '2x1': twoByOne,
      },
    }),
    ...(image?.caption && {
      captionData: {
        caption: image.caption,
      },
    }),
  };
};

const mapCmsDataToRows = (
  cmsData: NonNullable<
    NonNullable<AvailableHomePageData['homepageData']>['propertyComponents']
  >['patchworkGrid'][number]
) => {
  const rows: PatchworkGridType['rows'] = [];

  if (!cmsData.images?.length) {
    return null;
  }

  rows.push({
    description: cmsData.longDescription ?? undefined,
    headline: cmsData.headline ?? undefined,
    images: cmsData.images[0] ? [mapCmsImageToPatchworkImage(cmsData.images[0])] : [],
    ...(cmsData?.links?.[0] ? { link: mapCmsLinkToPatchworkLink(cmsData.links[0]) } : {}),
  });

  if (cmsData.images.length >= 3) {
    rows.push({
      images: cmsData.images.slice(1, 3).map(mapCmsImageToPatchworkImage),
    });
  }

  return {
    rows,
  };
};

export const mapHomePageDataToPatchworkGrids = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<PatchworkGridsProps> => {
  if (homepageData?.propertyComponents?.patchworkGrid?.length) {
    const mappedRows = homepageData.propertyComponents.patchworkGrid.flatMap(
      (cmsData) => mapCmsDataToRows(cmsData) ?? []
    );

    if (!mappedRows.length) {
      return DO_NOT_RENDER;
    }

    const patchworkGrids = mappedRows.map((rowsData, index) => ({
      ...rowsData,
      reverse: (index + 1) % 2 === 0,
    }));

    return {
      patchworkGrids,
    };
  }

  return DO_NOT_RENDER;
};
