import { useRouter } from 'next/router';
import { env } from '@dx-ui/framework-env';
import { baseUrl } from '../helpers/env-vars';

const replaceLANG = (stringValue: string, lang = 'en') => stringValue?.replace('__LANG__', lang);

export function useStaticUrls() {
  const router = useRouter();
  const lang = router?.locale || 'en';
  const path = router?.asPath || '/';
  const baseUrlWithLang = `${baseUrl}/${lang}`;
  const baseAppUrl = `${baseUrlWithLang}${path}`;
  const honorsSignInUrl = replaceLANG(env('DX_AUTH_UI'), lang);
  const honorsJoinUrl = replaceLANG(env('HONORS_JOIN_URL'), lang);
  const honorsAccountUrl = `${baseUrlWithLang}/hilton-honors/guest/my-account/`;

  return {
    baseAppUrl,
    honorsAccountUrl,
    honorsJoinUrl,
    honorsSignInUrl,
  };
}
