import Head from 'next/head';
import { facebookAppId, HotelPage } from '../../helpers/constants';
import { appVersion, baseUrl } from '../../helpers/env-vars';

import type {
  GallerySchema,
  GolfSchema,
  StandardSchema,
  HotelInfoSchema,
  SeoSchema,
  SpaSchema,
  RoomsSchema,
} from './page-schemas';
import {
  getGallerySchema,
  getGolfSchema,
  getHomeSchema,
  getHotelInfoSchema,
  getHotelLocationSchema,
  getRoomsSchema,
  getSpaSchema,
} from './page-schemas';
import { generateUrl } from '../../helpers/property-utils';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { useRouter } from 'next/router';
import { type LayoutData } from '../../helpers/layout.types';

type TPageHead = {
  brandLogoImageSrc?: string;
  metaDescription?: string;
  metaKeywords?: string;
  hideSearchEngine?: boolean;
  pageTitle: string;
  schemaName?: string;
  schemaUrl?: string;
  page?: ValuesOf<typeof HotelPage>;
  brandCode?: LayoutData['brandCode'];
  schema?: SeoSchema;
  shouldUseDefaultBrandFavicon?: boolean;
};

function getSeoSchema(page?: ValuesOf<typeof HotelPage>, seoSchema?: SeoSchema) {
  switch (page) {
    case HotelPage.GALLERY:
      return getGallerySchema(seoSchema as GallerySchema);
    case HotelPage.GOLF:
      return getGolfSchema(seoSchema as GolfSchema);
    case HotelPage.HOTELINFO:
      return getHotelInfoSchema(seoSchema as HotelInfoSchema);
    case HotelPage.ROOMS:
      return getRoomsSchema(seoSchema as RoomsSchema);
    case HotelPage.SPA:
      return getSpaSchema(seoSchema as SpaSchema);
    case HotelPage.LOCATION:
      return getHotelLocationSchema(seoSchema as StandardSchema);
    default:
      return getHomeSchema(seoSchema as StandardSchema);
  }
}

export function CustomHead({
  brandLogoImageSrc,
  hideSearchEngine = false,
  metaDescription,
  metaKeywords,
  pageTitle,
  page,
  brandCode,
  schema,
  shouldUseDefaultBrandFavicon = false,
}: TPageHead) {
  const brandAssetsUri = `${baseUrl}/modules/assets/img/brand`;
  const faviconSrc = shouldUseDefaultBrandFavicon
    ? `${brandAssetsUri}/WW/favicon_WW.ico`
    : brandCode
    ? `${brandAssetsUri}/${brandCode}/favicon_${brandCode}.ico`
    : null;

  const seoSchema = getSeoSchema(page, schema);
  const { fullyQualifiedUrl } = generateUrl(useRouter(), page !== 'home' ? page : '');
  const canonicalUrl = fullyQualifiedUrl;
  const isOffers = page === 'offers';
  const { template } = useGlobalConfig();

  return (
    <Head>
      <title>{pageTitle}</title>
      {faviconSrc ? <link rel="icon" href={faviconSrc} /> : null}
      {hideSearchEngine ? <meta name="robots" content="noindex, nofollow" /> : null}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Hilton" />
      <meta name="og:url" content={canonicalUrl} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={pageTitle} />
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && <meta name="og:description" content={metaDescription} />}
      <meta name="og:site_name" content="Hilton" />
      {brandLogoImageSrc ? <meta name="og:image" content={brandLogoImageSrc} /> : null}
      <meta name="dx-ui-service" content="property-ui" />
      <meta name="dx-version" content={appVersion} />
      {canonicalUrl && !isOffers ? (
        <link rel="canonical" href={canonicalUrl} data-testid="canonical" />
      ) : null}
      {metaKeywords ? <meta name="keywords" content={metaKeywords} /> : null}
      {facebookAppId ? <meta name="fb:app_id" content={facebookAppId} /> : null}
      <meta name="templateType" content={template} />
      {seoSchema ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(seoSchema) }}
        />
      ) : null}
    </Head>
  );
}
