import classnames from 'classnames';
import type { useMenu } from './hooks/use-control-menu';
import type { VideoControlsProps } from './video-player.controls';
import { getClasses } from './util/classes';

export function ControlMenu(
  props: ReturnType<typeof useMenu> &
    Pick<VideoControlsProps, 'brandComponentTheme'> &
    Pick<React.ComponentProps<'div'>, 'children' | 'className'>
) {
  const { brandComponentTheme, menuId, popupMenuRef, isOpen, children, className } = props;
  const isDark = brandComponentTheme === 'dark';

  return (
    <div
      id={menuId}
      ref={popupMenuRef}
      className={classnames(
        'border-border absolute bottom-full start-0 w-full border-y shadow-md md:min-w-60',
        getClasses({ brandComponentTheme }),
        {
          'border-bg-dark': isDark,
          'motion-safe:animate-fadein': isOpen,
          'motion-safe:animate-fadeout': !isOpen,
          hidden: !isOpen,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
