import type { TrackOption, VideoTrack } from '../video-player.controls';

type FindArgs = {
  activeTrack: TrackOption;
  selectedVideoTrack: VideoTrack;
};

/**
 * @see findTrack
 */
export function findCaption({ selectedVideoTrack, activeTrack }: FindArgs) {
  return findTrack(selectedVideoTrack?.captionTracks, activeTrack);
}

/**
 * @see findTrack
 */
export function findTranscript({ selectedVideoTrack, activeTrack }: FindArgs) {
  return findTrack(selectedVideoTrack?.transcriptTracks, activeTrack);
}

/**
 * Attempts to find the related track based on the active track.
 */
function findTrack(tracks: TrackOption[], activeTrack: TrackOption) {
  return tracks.find((track) => track.srclang === activeTrack.srclang) || tracks[0] || null;
}
