import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';
import type { useVideoPlayer } from './hooks/use-video-player';
import { getCueText } from './util/get-cue-text';
import { isValidCue } from './util/is-valid-cue';

type VideoCaptionsProps = ReturnType<typeof useVideoPlayer>['videoCaptionProps'] &
  Pick<VideoControlsProps, 'brandComponentTheme'> &
  React.ComponentProps<'div'>;

export function VideoCaptions({
  showCaptions,
  activeCaptionTrack,
  brandComponentTheme: _brandComponentTheme,
  ...elementProps
}: VideoCaptionsProps) {
  const activeCues = showCaptions
    ? (Array.from(activeCaptionTrack?.track.activeCues ?? []) as VTTCue[]).filter(isValidCue)
    : [];

  if (activeCues.length) {
    return (
      <div
        {...elementProps}
        className={classnames(
          'bg-text/75 text-text-inverse flex flex-col items-center justify-center overflow-auto p-2 lg:text-lg',
          elementProps.className
        )}
      >
        {activeCues.map((cue) => (
          <div
            key={cue.text}
            dangerouslySetInnerHTML={{
              __html: getCueText(cue),
            }}
          />
        ))}
      </div>
    );
  }

  return null;
}
