import type { CSSProperties } from 'react';
import type { PossibleThumbs, TSlider } from './slider.types';

type UseInlineGradient = Pick<
  TSlider<PossibleThumbs, PossibleThumbs>,
  'disabled' | 'offColor' | 'onColor' | 'orientation' | 'brandComponentTheme' | 'variant'
> & {
  /**
   * Array of 3-tuples. First value determines "on" or "off", 0 or 1, respectively.
   * The remainder specify the `on` percentages in the gradient [0,10], [40,50].
   */
  values: Tuple<3, number>[];
  /** Whether the language displayed on the page is right to left */
  isRtlLanguage?: boolean;
};

export const defaultColorVariables = {
  offColor: 'color-border-form',
  onColor: 'color-secondary',
  disabledColor: 'color-bg-disabled',
} as const;

export const primaryColorVariables = {
  offColor: 'color-primary',
  onColor: 'color-primary',
} as const;

const customColorVariables = {
  offColor: 'slider-gradient-off-color',
  onColor: 'slider-gradient-on-color',
};

/**
 * This hook is used specifically to return css for the horizontal gradient in the `Slider` component
 * using the colors from the [style guide](/?path=/docs/library-styles-colors--colors)
 *
 * > Ideally this inline styling could eventually be replaced with css vars via TW plugin.
 */
export const useInlineGradient = ({
  values,
  disabled = false,
  offColor = defaultColorVariables.offColor,
  onColor = defaultColorVariables.onColor,
  isRtlLanguage = false,
  orientation = 'horizontal',
  variant = 'default',
  brandComponentTheme,
}: UseInlineGradient): CSSProperties => {
  const direction = getDirection({ isRtlLanguage, orientation });
  const { on: gradientOnColor, off: gradientOffColor } = getGradientColors({
    brandComponentTheme,
    variant,
    offColor,
    onColor,
  });
  const gradientDisabledColor = `var(--${defaultColorVariables.disabledColor})`;
  const on = `rgb(${disabled ? gradientDisabledColor : gradientOnColor})`;
  const off = `rgb(${disabled ? gradientDisabledColor : gradientOffColor})`;
  const collect = values.reduce((prev, val) => {
    /** first value determines if section is filled or not */
    const filled = Boolean(val[0]);
    return `${prev}, ${filled ? on : off} ${val[1]}%, ${filled ? on : off} ${val[2]}%`;
  }, ``);
  const background = `linear-gradient(to ${direction}${collect})`;
  return { background };
};

function getDirection({
  orientation,
  isRtlLanguage,
}: Pick<UseInlineGradient, 'orientation' | 'isRtlLanguage'>) {
  if (orientation === 'vertical') {
    return 'top';
  }
  if (isRtlLanguage) {
    return 'left';
  }
  return 'right';
}

function getGradientColors({
  brandComponentTheme,
  variant,
  onColor,
  offColor,
}: Pick<UseInlineGradient, 'brandComponentTheme' | 'variant' | 'offColor' | 'onColor'>) {
  const themeSuffix = brandComponentTheme ? `-${brandComponentTheme}` : '';
  const presetVariables = variant === 'primary' ? primaryColorVariables : { onColor, offColor };
  return {
    off: `var(--${customColorVariables.offColor}${themeSuffix}, var(--${presetVariables.offColor}))`,
    on: `var(--${customColorVariables.onColor}${themeSuffix}, var(--${presetVariables.onColor}))`,
  };
}

export default useInlineGradient;
