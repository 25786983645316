import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { VoucherInfoProps } from './voucher-info';

export const mapHomePageDataToVoucherInfo = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<VoucherInfoProps> =>
  homepageData?.facilityOverview?.voucherUrl
    ? {
        hotelName: homepageData?.name,
        voucherUrl: homepageData.facilityOverview.voucherUrl,
      }
    : DO_NOT_RENDER;
