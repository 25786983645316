import type { AvailableHomePageData } from '../../../helpers/componentMapping/homePageComponents';
import type { THotelPolicies } from './HotelPolicies';

export const mapHomePageDataToHotelPolicies = ({
  homepageData,
}: AvailableHomePageData): THotelPolicies => ({
  facilityInfo: homepageData?.facilityOverview || {},
  petsInfo: homepageData?.pets,
  policy: homepageData?.policy,
  resortPolicyText: homepageData?.overview?.resortFeeDisclosureDesc,
  safetyInfo: homepageData?.safetyAndSecurity || {},
});
