import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { TLocationAndTransportationProps } from './location-and-transportation';

export const mapHomePageDataToLocationAndTransportation = ({
  ctyhocn,
  homepageData,
  layout,
}: AvailableHomePageData): TLocationAndTransportationProps => ({
  address: layout?.address,
  airports: homepageData?.airports,
  brandCode: layout?.brandCode ?? '',
  coordinate: homepageData?.localization?.coordinate,
  ctyhocn,
  hideBrandLogo: layout?.headerData?.suppressLogo,
  hotelName: homepageData?.name ?? '',
  serviceAmenities: homepageData?.serviceAmenities,
});
