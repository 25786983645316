import classnames from 'classnames';

type Args = {
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const getTextColorClasses = ({ brandComponentTheme }: Args) => {
  const isLight = brandComponentTheme === 'light';
  const isDark = brandComponentTheme === 'dark';
  return classnames({
    'text-primary brand-ht:text-text-inverse': isLight,
    'text-text-inverse brand-gu:!text-primary brand-es:!text-primary brand-nd:!text-primary':
      isDark,
  });
};

export const getBackgroundColorClasses = ({ brandComponentTheme }: Args) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return classnames({
    'bg-bg brand-ey:bg-bg-light brand-ou:bg-secondary': !isDark && !isLight,
    'bg-bg-dark brand-es:bg-bg brand-gu:bg-bg brand-nd:bg-bg': isDark,
    'bg-bg-light brand-es:bg-bg brand-ey:bg-bg brand-gu:bg-bg brand-nd:bg-bg': isLight,
  });
};

export const getClasses = ({ brandComponentTheme }: Args) => {
  return classnames(
    getTextColorClasses({ brandComponentTheme }),
    getBackgroundColorClasses({ brandComponentTheme })
  );
};
