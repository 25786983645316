import { DO_NOT_RENDER } from '../../helpers/constants';
import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { TRoomsOverviewProps } from './RoomsOverview';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { convertCmsImageRatiosToMap } from '../../helpers/property-utils';

const mapCmsImageToRoomsOverviewImg = (
  img: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['roomTypeCategories']
  >[number]['image']
) => {
  if (!img) {
    return;
  }
  const { oneByOne, threeByTwo } = convertCmsImageRatiosToMap(img.ratios);
  return {
    ...(oneByOne && {
      oneByOneRatioImgUrl: oneByOne ?? '',
    }),
    ...(threeByTwo && {
      threeByTwoRatioImgUrl: threeByTwo ?? '',
    }),
    variants: img.variants,
    altText: img.altText || undefined,
  };
};

const transformRoomCategoriesData = (
  roomCategoriesData: NonNullable<GetHotelHomePageQuery['hotel']>['roomTypeCategories']
) =>
  roomCategoriesData.map((roomCategory) => {
    if (!roomCategory.image) {
      return roomCategory;
    }
    const imageData = mapCmsImageToRoomsOverviewImg(roomCategory.image);
    return {
      ...roomCategory,
      image: imageData,
    };
  });

export const mapHomePageDataToRoomsOverview = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<TRoomsOverviewProps> => {
  if (layout?.resEnabled && homepageData?.roomTypeCategories?.length) {
    const roomCategoriesData = transformRoomCategoriesData(homepageData.roomTypeCategories);
    return {
      roomCategoriesData,
    };
  }

  return DO_NOT_RENDER;
};
