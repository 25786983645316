import { useId, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { addTrackAsync } from '../util/add-track';
import { logWarning } from '@dx-ui/framework-logger';

import type { VideoPlayerProps } from './use-video-player';
import type { VideoTrack } from '../video-player.controls';

type UseTrackQueryOptions = VideoPlayerProps & {
  videoElement: React.RefObject<HTMLVideoElement>;
};

/**
 * Hook that asynchronously fetches and adds tracks to the video element.
 *
 * @see addTrackAsync
 */
export function useTrackQuery(options: UseTrackQueryOptions) {
  const id = useId();
  const videoElement = options.videoElement.current;
  const hasCaptions = Boolean(options.captionTracks?.length);
  const hasTranscripts = Boolean(options.transcriptTracks?.length);
  const hasAudioTracks = Boolean(options.audioTracks?.length);
  const hasTracks = hasCaptions || hasTranscripts || hasAudioTracks;
  const { defaultVideoTrack, baseVideoTrack } = useBaseTracks(options);
  const allTracks = useMemo(() => getFetchableTracks(options), [options]);

  const { data: videoTracks = [defaultVideoTrack] } = useQuery({
    enabled: Boolean(hasTracks && videoElement),
    queryKey: [`video:${id}`],
    queryFn: async () => {
      return Promise.all(
        allTracks.map((track) => addTrackAsync(videoElement as HTMLVideoElement, track))
      )
        .then((htmlTracks) => {
          return [baseVideoTrack, ...(options?.audioTracks || [])].map((videoTrack) => ({
            ...videoTrack,
            captionTracks: matchTracks({
              htmlTracks,
              videoTrack,
              type: 'captionTracks',
            }),
            transcriptTracks: matchTracks({
              htmlTracks,
              videoTrack,
              type: 'transcriptTracks',
            }),
          }));
        })
        .catch((error) => {
          logWarning('OSCVideoPlayer', error, 'Failed to fetch video tracks');
          return [defaultVideoTrack];
        });
    },
  });

  return videoTracks;
}

function getFetchableTracks(options: UseTrackQueryOptions) {
  const baseTracks = [...(options?.captionTracks || []), ...(options?.transcriptTracks || [])];
  const additionalTracks =
    options?.audioTracks?.flatMap((videoTrack) => [
      ...(videoTrack.captionTracks || []),
      ...(videoTrack.transcriptTracks || []),
    ]) || [];
  const tracksMap = new Map(
    [...baseTracks, ...additionalTracks].map((item) => [Object.values(item).join('-'), item])
  );
  return Array.from(tracksMap.values());
}

function matchTracks({
  htmlTracks,
  videoTrack,
  type,
}: {
  htmlTracks: Awaited<ReturnType<typeof addTrackAsync>>[];
  videoTrack: NonNullable<VideoPlayerProps['audioTracks']>[number];
  type: keyof Pick<VideoTrack, 'captionTracks' | 'transcriptTracks'>;
}) {
  return htmlTracks.filter((htmlTrack): htmlTrack is HTMLTrackElement =>
    (videoTrack[type] ?? []).some(
      (track) =>
        track.type === htmlTrack?.dataset.type &&
        track.label === htmlTrack.label &&
        track.url === htmlTrack.src
    )
  );
}

function useBaseTracks(options: UseTrackQueryOptions) {
  const baseVideoTrack = {
    videoUrl: options.videoUrl,
    videoGroup: options.videoGroup,
    videoLabel: options.videoLabel,
    captionTracks: options.captionTracks,
    transcriptTracks: options.transcriptTracks,
  };
  const defaultVideoTrack = {
    ...baseVideoTrack,
    captionTracks: [],
    transcriptTracks: [],
  };
  return {
    baseVideoTrack,
    defaultVideoTrack,
  };
}
