import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { type TTabbedOverview } from './TabbedOverview';

export const getOnSiteRestaurants = (
  restaurants:
    | NonNullable<
        NonNullable<AvailableHomePageData['homepageData']>['restaurantOverview']
      >['restaurants']
    | undefined
) => restaurants?.filter((restaurant) => restaurant.onSite === true);

const getDiningTraits = (
  restaurants:
    | NonNullable<
        NonNullable<AvailableHomePageData['homepageData']>['restaurantOverview']
      >['restaurants']
    | undefined
) => {
  const onSiteRestaurants = getOnSiteRestaurants(restaurants);
  const traits = [];
  const hasDiningWithCTA =
    ((onSiteRestaurants?.length ?? 0) === 1 && onSiteRestaurants?.[0]?.diningMenus?.length) ||
    (onSiteRestaurants?.length ?? 0) > 1;

  if (onSiteRestaurants?.length) {
    const diningTrait = hasDiningWithCTA
      ? 'dining-component-type:dining-with-cta'
      : 'dining-component-type:dining-with-no-cta';
    traits.push(diningTrait, 'dining-component:has-onsite-dining');
  } else {
    traits.push('dining-component:no-onsite-dining');
  }

  return traits;
};

export const mapHomePageDataToTabbedOverview = ({
  homepageData,
  showJapaneseDiningComponent,
}: AvailableHomePageData): TTabbedOverview => {
  const traits = getDiningTraits(homepageData?.restaurantOverview?.restaurants);
  const onSiteRestaurants = getOnSiteRestaurants(homepageData?.restaurantOverview?.restaurants);

  if (showJapaneseDiningComponent) {
    return { golfOverview: homepageData?.golf, spaOverview: homepageData?.spa };
  }

  return {
    golfOverview: homepageData?.golf,
    onSiteRestaurants,
    restaurantOverview: homepageData?.restaurantOverview,
    spaOverview: homepageData?.spa,
    traits,
  };
};

export const mapHomePageDataToJapaneseDiningComponent = ({
  homepageData,
  showJapaneseDiningComponent,
}: AvailableHomePageData): TConditionalRender<TTabbedOverview> => {
  const onSiteRestaurants = getOnSiteRestaurants(homepageData?.restaurantOverview?.restaurants);

  const traits = getDiningTraits(homepageData?.restaurantOverview?.restaurants);

  if (onSiteRestaurants?.length && showJapaneseDiningComponent) {
    return {
      restaurantOverview: homepageData?.restaurantOverview,
      onSiteRestaurants,
      traits,
    };
  }

  return DO_NOT_RENDER;
};
