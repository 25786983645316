import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';
import { getClasses } from './util/classes';

export function ControlList(
  props: Omit<React.ComponentProps<'ul'>, 'role'> & Pick<VideoControlsProps, 'brandComponentTheme'>
) {
  const { children, brandComponentTheme, ...listProps } = props;

  return (
    <ul
      {...listProps}
      role="menu"
      className={classnames(
        'motion-safe:animate-fadein divide-border flex w-full flex-col divide-y',
        getClasses({ brandComponentTheme }),
        listProps.className
      )}
    >
      {children}
    </ul>
  );
}
