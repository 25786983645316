import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { StayTourVideo } from './stay-tour-video';

export const mapHomePageDataToStayTourVideo = ({
  homepageData,
  isStayTourVideoEnabled,
  layout,
}: AvailableHomePageData): TConditionalRender<StayTourVideo> => {
  if (isStayTourVideoEnabled && homepageData?.video) {
    return {
      hotelName: layout?.hotelName,
      video: homepageData.video,
    };
  }

  return DO_NOT_RENDER;
};
