import { useRef } from 'react';
import classnames from 'classnames';
import { useVideoPlayer } from './hooks/use-video-player';
import { Video } from './video-player.player';
import { VideoControls } from './video-player.controls';
import { VideoCaptions } from './video-player.captions';
import { VideoTranscript } from './video-player.transcripts';
import { useVideoPlayerClasses } from './hooks/use-video-player-classnames';
import { VideoPlayerMarkup } from './video-player.markup';

type HookProps = Parameters<typeof useVideoPlayer>[0];
type VideoProps = Omit<React.ComponentProps<typeof Video>, 'videoElement' | 'isAutoPlay'>;
type CaptionProps = Pick<React.ComponentProps<typeof VideoCaptions>, 'className'>;
type TranscriptProps = Pick<React.ComponentProps<typeof VideoTranscript>, 'className'>;
type ControlsProps = Pick<React.ComponentProps<typeof VideoControls>, 'className'>;
type Orientation = 'portrait' | 'landscape';

type VideoPlayerProps = HookProps &
  VideoProps &
  Partial<{
    brandComponentTheme: CmsBrandComponentTheme;
    videoCaptionsProps: CaptionProps;
    videoControlsProps: ControlsProps;
    videoTranscriptProps: TranscriptProps;
    wrapperClassName: React.ComponentProps<'div'>['className'];
    orientation: Orientation;
  }> & {
    markupSchemas?: React.ComponentProps<typeof VideoPlayerMarkup>['markupSchemas'];
  };

/**
 * Native video player that composes a number of components to render a video with
 * support for closed captions, audio descriptions, and transcripts.
 *
 * @note Does not support ABS (Adaptive bitrate streaming).
 */
export function VideoPlayer({
  videoCaptionsProps,
  videoControlsProps,
  videoTranscriptProps,
  audioTracks,
  captionTracks,
  transcriptTracks,
  brandComponentTheme,
  videoGroup = 'standard',
  videoLabel,
  videoName,
  wrapperClassName,
  orientation = 'landscape',
  markupSchemas,
  ...videoProps
}: VideoPlayerProps) {
  const wrapperElement = useRef<React.ElementRef<'div'>>(null);
  const isLandscape = orientation === 'landscape';
  const playerClasses = useVideoPlayerClasses({ isLandscape });
  const playerProps = useVideoPlayer({
    ...videoProps,
    videoGroup,
    videoLabel,
    videoName,
    wrapperElement,
    audioTracks,
    captionTracks,
    transcriptTracks,
  });

  return (
    <>
      <div
        className={classnames(playerClasses.wrapperClassNames, wrapperClassName)}
        ref={wrapperElement}
      >
        <Video {...videoProps} {...playerProps.videoProps} />
        <VideoCaptions
          {...videoCaptionsProps}
          {...playerProps.videoCaptionProps}
          brandComponentTheme={brandComponentTheme}
          className={classnames(playerClasses.captionClassNames, videoCaptionsProps?.className)}
        />
        <VideoControls
          {...videoControlsProps}
          {...playerProps.videoControlsProps}
          brandComponentTheme={brandComponentTheme}
          className={classnames(playerClasses.controlsClassNames, videoControlsProps?.className)}
        />
      </div>
      <VideoTranscript
        {...playerProps.videoTranscriptProps}
        brandComponentTheme={brandComponentTheme}
        className={classnames(playerClasses.transcriptClassNames, videoTranscriptProps?.className)}
      />
      <VideoPlayerMarkup markupSchemas={markupSchemas} />
    </>
  );
}
