import type { GetHotelHomePageQuery } from '../../generated/types';
import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { convertCmsImageRatiosToMap } from '../../helpers/property-utils';
import type { TStickySideBySideProps } from './StickySideBySide.types';

const mapCmsImageToStickyImageData = (
  img: NonNullable<
    NonNullable<NonNullable<GetHotelHomePageQuery>['hotel']>['restaurantOverview']
  >['images'][number]
) => {
  const { threeByTwo } = convertCmsImageRatiosToMap(img.ratios);
  return {
    url: threeByTwo || '',
    ...(img.caption && {
      caption: img.caption,
    }),
    ...(img.altText && {
      alt: img.altText,
    }),
  };
};

export const mapHomePageDataToStickySideBySide = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<TStickySideBySideProps> => {
  if (homepageData?.restaurantOverview) {
    const stickyImagesData = homepageData?.restaurantOverview?.images
      .slice(0, 4)
      .filter((img) => img.url)
      .map((img) => mapCmsImageToStickyImageData(img));

    if (stickyImagesData?.length > 1) {
      const onSiteRestaurants = homepageData?.restaurantOverview?.restaurants?.filter(
        (restaurants) => restaurants.onSite === true
      );
      const diningNavLink =
        layout?.headerData?.mainNavLinks?.find(({ link }) => link?.url.endsWith('/dining/'))?.link
          ?.url || '';
      const isCta = (onSiteRestaurants?.length > 1 && diningNavLink) || false;
      return {
        headline: homepageData?.restaurantOverview?.headline || '',
        description: homepageData?.restaurantOverview?.desc || '',
        images: stickyImagesData,
        ctaUrl: isCta ? diningNavLink : undefined,
      };
    }
  }
  return DO_NOT_RENDER;
};
