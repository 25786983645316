import classnames from 'classnames';
import { Icon } from '@dx-ui/osc-icon';
import type { IconProps } from '@dx-ui/osc-icon';

export function BrandIcon(props: IconProps) {
  return (
    <span className="inline-block self-center">
      <Icon {...props} className={classnames('me-2', props.className)} />
    </span>
  );
}
