import classnames from 'classnames';
import { forwardRef } from 'react';
import type { VideoControlsProps } from './video-player.controls';
import { getClasses } from './util/classes';

type ControlButtonProps = Omit<React.ComponentProps<'button'>, 'type'> &
  Pick<VideoControlsProps, 'brandComponentTheme'> & {
    iconContent: React.ReactNode;
    wrapperClassName?: React.ComponentProps<'li'>['className'];
  };

const ControlButton = forwardRef<HTMLButtonElement, ControlButtonProps>(function ControlButton(
  props,
  ref
) {
  const { brandComponentTheme, children, iconContent, wrapperClassName, ...buttonProps } = props;
  return (
    <li className={wrapperClassName}>
      <button
        {...buttonProps}
        ref={ref}
        type="button"
        className={classnames(
          'bg-bg z-1 focus:z-2 relative inline-flex size-12 items-center justify-center',
          getClasses({ brandComponentTheme }),
          buttonProps.className
        )}
      >
        {iconContent}
      </button>
      {children}
    </li>
  );
});

export { ControlButton };
