import type { HotelDescriptionProps } from './hotel-description';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';

const brandsIncludedInCurvedHeader = ['GU'];

const shouldShowCurvedHeader = (brandCode: string) =>
  brandsIncludedInCurvedHeader.includes(brandCode);

const shouldHideHotelDescriptors = (brandCode: string) => ['LX'].includes(brandCode);

const getHotelDescriptors = (
  brandCode: string,
  homepageData: AvailableHomePageData['homepageData']
): { descriptors: HotelDescriptionProps['descriptors'] } => {
  const rooms = homepageData?.structure?.numRooms ?? 0;
  const floors = homepageData?.structure?.numFloors ?? 0;
  const restaurants = homepageData?.foodAndBeverage?.numberOfRestaurants ?? 0;

  if (shouldHideHotelDescriptors(brandCode)) {
    return {
      descriptors: undefined,
    };
  }

  if (rooms && (floors || restaurants)) {
    return {
      descriptors: {
        rooms,
        floors,
        restaurants,
      },
    };
  }

  return {
    descriptors: undefined,
  };
};

export const mapHomePageDataToHotelDescription = ({
  layout,
  homepageData,
}: AvailableHomePageData): HotelDescriptionProps => {
  const brandCode = homepageData?.brandCode ?? '';

  const baseProps = {
    shortDesc: homepageData?.facilityOverview?.shortDesc ?? '',
    ...getHotelDescriptors(brandCode, homepageData),
  };

  if (shouldShowCurvedHeader(brandCode)) {
    return {
      curvedHeader: true,
      curvedText: 'Welcome To',
      textBelow: layout?.address?.mapCity ?? '',
      ...baseProps,
    };
  }

  return {
    curvedHeader: false,
    headline: homepageData?.facilityOverview?.headline ?? '',
    ...baseProps,
  };
};
