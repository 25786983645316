import classnames from 'classnames';
import { useMemo } from 'react';

type UseVideoPlayerClassesProps = Partial<{ isLandscape: boolean }>;

export function useVideoPlayerClasses({ isLandscape = true }: UseVideoPlayerClassesProps) {
  return useMemo(
    () => ({
      captionClassNames: classnames(
        'absolute inset-x-0 bottom-8 mx-auto w-[calc(100%-2rem)] sm:max-w-fit',
        {
          'sm:bottom-16 sm:mb-2': isLandscape,
        }
      ),
      controlsClassNames: classnames(
        'absolute -bottom-6 start-1/2 z-10 -translate-x-1/2 rtl:translate-x-1/2',
        {
          'sm:bottom-4 sm:start-4 sm:translate-x-0 sm:rtl:translate-x-0': isLandscape,
        }
      ),
      transcriptClassNames: 'container p-4 pt-8 sm:pt-4',
      wrapperClassNames: 'relative',
    }),
    [isLandscape]
  );
}
