import set from 'lodash/set';

export function getMetricsTrackName(htmlTrack: HTMLTrackElement | null) {
  return htmlTrack ? formatName(htmlTrack.label) : 'off';
}

export function getMetricsVideoName(videoName: string) {
  return formatName(videoName);
}

export type Events =
  | 'user_play'
  | 'autoplay'
  | 'sound'
  | 'cc'
  | `sub_${string}`
  | `audiotrack_${string}`
  | `transcript_${string}`;

export function trackEvent(eventName: Events, videoName: string) {
  if (window._satellite && window.digitalData) {
    set(window.digitalData, 'click.clickID', `video_${eventName}`);
    set(window.digitalData, 'page.attributes.videoName', videoName);
    window._satellite.track?.('global_click');
  }
}

function formatName(name: string) {
  return name
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s/g, '_')
    .toLowerCase();
}
