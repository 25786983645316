import dynamic from 'next/dynamic';

import { homePageComponents } from '../../config/components';

import { mapHomePageDataToPropertyAlerts } from '../../components/alerts-property/dataMappers';
import { mapHomePageDataToCelebrityQuote } from '../../components/celebrity-quote/dataMappers';
import { mapHomePageDataToDescriptionAmenities } from '../../components/description-amenities/dataMappers';
import { mapHomePageDataToEventInfo } from '../../components/events-info/dataMappers';
import { mapHomePageDataToFullWidthDynamicHero } from '../../components/full-width-dynamic-hero/dataMappers';
import { mapHomePageDataToFullWidthCarousel } from '../../components/full-width-image-carousel/dataMappers';
import { mapHomePageDataToFullWidthVideo } from '../../components/full-width-video/dataMappers';
import { mapHomePageDataToHalfAndHalfCarousel } from '../../components/half-and-half-carousel/dataMappers';
import { mapHomePageDataToHonors } from '../../components/honors/dataMappers';
import { mapHomePageDataToHotelDescription } from '../../components/hotel-description/dataMappers';
import { mapHomePageDataToInspiredHero } from '../../components/inspired-hero/dataMappers';
import { mapHomePageDataToLocationAndTransportation } from '../../components/location-and-transportation/dataMappers';
import { mapHomePageDataToPartnershipInfo } from '../../components/partnership-info/dataMappers';
import { mapHomePageDataToPatchworkGrids } from '../../components/patchwork-grids/dataMappers';
import { mapHomePageDataToHotelPolicies } from '../../components/property/hotel-policies/dataMappers';
import { mapHomePageDataToRoomsOverview } from '../../components/rooms-overview/dataMappers';
import { mapHomePageDataToStayTourVideo } from '../../components/stay-tour-video/dataMappers';
import { mapHomePageDataToStickySideBySide } from '../../components/sticky-side-by-side-component/dataMappers';
import {
  mapHomePageDataToJapaneseDiningComponent,
  mapHomePageDataToTabbedOverview,
} from '../../components/tabbed-overview/dataMappers';
import { mapHomePageDataToUtilityRail } from '../../components/utility-rail/dataMappers';
import { mapHomePageDataToVerticalPolicyTabs } from '../../components/vertical-policy-tabs/dataMappers';
import { mapHomePageDataToVoucherInfo } from '../../components/voucher-info/dataMappers';

import type PropertyAlertsComponent from '../../components/alerts-property';
import type CelebrityQuoteComponent from '../../components/celebrity-quote/CelebrityQuote';
import type DescriptionAmenitiesComponent from '../../components/description-amenities/description-amenities';
import type EventsInfoComponent from '../../components/events-info/events-info';
import type FullWidthDynamicHeroComponent from '../../components/full-width-dynamic-hero/full-width-dynamic-hero';
import type FullWidthHeroImageCarouselComponent from '../../components/full-width-image-carousel/full-width-image-carousel';
import type FullWidthVideoComponent from '../../components/full-width-video/full-width-video';
import type HalfAndHalfCarouselComponent from '../../components/half-and-half-carousel/HalfAndHalfCarousel';
import type HonorsComponent from '../../components/honors/honors';
import type HotelDescriptionComponent from '../../components/hotel-description/hotel-description';
import type InspiredHeroComponent from '../../components/inspired-hero/InspiredHero';
import type LocationAndTransportationComponent from '../../components/location-and-transportation/location-and-transportation';
import type PartnershipInfoComponent from '../../components/partnership-info/partnership-info';
import type PatchworkGridsComponent from '../../components/patchwork-grids/PatchworkGrids';
import type HotelPoliciesComponent from '../../components/property/hotel-policies/HotelPolicies';
import type RoomsOverviewComponent from '../../components/rooms-overview/RoomsOverview';
import type StayTourVideoComponent from '../../components/stay-tour-video/stay-tour-video';
import type StickySideBySideComponent from '../../components/sticky-side-by-side-component/StickySideBySideDesktopComponent';
import type JapaneseDiningComponent from '../../components/tabbed-overview/JapaneseDiningComponent';
import type TabbedOverviewComponent from '../../components/tabbed-overview/TabbedOverview';
import type UtilityRailComponent from '../../components/utility-rail/utility-rail';
import type VerticalPolicyTabsComponent from '../../components/vertical-policy-tabs/VerticalPolicyTabs';
import type VoucherInfoComponent from '../../components/voucher-info/voucher-info';

import type { TTabbedOverview } from '../../components/tabbed-overview/TabbedOverview';
import type { THomePageComponents } from '../../config/components';
import type { GetHotelHomePageQuery } from '../../generated/types';
import type { LayoutData } from '../layout.types';

export type MappedComponents =
  | Parameters<(typeof availableComponents)[THomePageComponents]>[0]
  | null;
export type AvailableHomePageData = {
  showJapaneseDiningComponent: boolean;
  ctyhocn: string;
  homepageData: GetHotelHomePageQuery['hotel'];
  isStayTourVideoEnabled: boolean;
  layout: LayoutData;
  showMeetingsSimplified: boolean;
  onSiteRestaurants: TTabbedOverview['onSiteRestaurants'];
};

/**
 * This function should be used in place of a data mapper
 * in the `componentDataMappers` object when no external
 * data is required to render the component.
 * @returns null
 */
export const NO_MAPPING_REQUIRED = () => null;

const CelebrityQuote = dynamic(
  () => import('../../components/celebrity-quote/CelebrityQuote').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof CelebrityQuoteComponent;

const JapaneseDining = dynamic(
  () =>
    import('../../components/tabbed-overview/JapaneseDiningComponent').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof JapaneseDiningComponent;

const DescriptionAmenities = dynamic(
  () =>
    import('../../components/description-amenities/description-amenities').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof DescriptionAmenitiesComponent;

const EventsInfo = dynamic(
  () => import('../../components/events-info/events-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof EventsInfoComponent;

const FullWidthDynamicHero = dynamic(
  () =>
    import('../../components/full-width-dynamic-hero/full-width-dynamic-hero').then(
      (module) => module.default
    ),
  { ssr: true }
) as typeof FullWidthDynamicHeroComponent;

const FullWidthHeroImageCarousel = dynamic(
  () =>
    import('../../components/full-width-image-carousel/full-width-image-carousel').then(
      (module) => module.default
    ),
  { ssr: true }
) as typeof FullWidthHeroImageCarouselComponent;

const FullWidthVideo = dynamic(
  () =>
    import('../../components/full-width-video/full-width-video').then((module) => module.default),
  { ssr: true }
) as typeof FullWidthVideoComponent;

const HalfAndHalfCarousel = dynamic(
  () =>
    import('../../components/half-and-half-carousel/HalfAndHalfCarousel').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof HalfAndHalfCarouselComponent;

const Honors = dynamic(
  () => import('../../components/honors/honors').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof HonorsComponent;

const HotelDescription = dynamic(
  () =>
    import('../../components/hotel-description/hotel-description').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof HotelDescriptionComponent;

const HotelPolicies = dynamic(
  () =>
    import('../../components/property/hotel-policies/HotelPolicies').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof HotelPoliciesComponent;

const InspiredHero = dynamic(
  () => import('../../components/inspired-hero/InspiredHero').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof InspiredHeroComponent;

const LocationAndTransportation = dynamic(
  () =>
    import('../../components/location-and-transportation/location-and-transportation').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof LocationAndTransportationComponent;

const PartnershipInfo = dynamic(
  () =>
    import('../../components/partnership-info/partnership-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PartnershipInfoComponent;

const PatchworkGrids = dynamic(
  () => import('../../components/patchwork-grids/PatchworkGrids').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PatchworkGridsComponent;

const PropertyAlerts = dynamic(
  () => import('../../components/alerts-property').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PropertyAlertsComponent;

const RoomsOverview = dynamic(
  () => import('../../components/rooms-overview/RoomsOverview').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof RoomsOverviewComponent;

const StayTourVideo = dynamic(
  () => import('../../components/stay-tour-video/stay-tour-video').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof StayTourVideoComponent;

const StickySideBySide = dynamic(
  () =>
    import('../../components/sticky-side-by-side-component/StickySideBySideBaseContainer').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof StickySideBySideComponent;

const TabbedOverview = dynamic(
  () => import('../../components/tabbed-overview/TabbedOverview').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof TabbedOverviewComponent;

const UtilityRail = dynamic(
  () => import('../../components/utility-rail/utility-rail').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof UtilityRailComponent;

const VerticalPolicyTabs = dynamic(
  () =>
    import('../../components/vertical-policy-tabs/VerticalPolicyTabs').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof VerticalPolicyTabsComponent;

const VoucherInfo = dynamic(
  () => import('../../components/voucher-info/voucher-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof VoucherInfoComponent;

export const availableComponents = {
  [homePageComponents.CELEBRITY_QUOTE]: CelebrityQuote,
  [homePageComponents.DESCRIPTION_AMENITIES]: DescriptionAmenities,
  [homePageComponents.EVENTS_INFO]: EventsInfo,
  [homePageComponents.FULL_WIDTH_DYNAMIC_HERO]: FullWidthDynamicHero,
  [homePageComponents.FULL_WIDTH_IMAGE_CAROUSEL]: FullWidthHeroImageCarousel,
  [homePageComponents.FULL_WIDTH_VIDEO]: FullWidthVideo,
  [homePageComponents.HALFANDHALF_CAROUSEL]: HalfAndHalfCarousel,
  [homePageComponents.HONORS]: Honors,
  [homePageComponents.HOTEL_DESCRIPTION]: HotelDescription,
  [homePageComponents.HOTEL_POLICIES]: HotelPolicies,
  [homePageComponents.INSPIRED_HERO]: InspiredHero,
  [homePageComponents.LOCATION_AND_TRANSPORTATION]: LocationAndTransportation,
  [homePageComponents.PARTNERSHIP_INFO]: PartnershipInfo,
  [homePageComponents.PATCHWORK_GRIDS]: PatchworkGrids,
  [homePageComponents.PROPERTY_ALERTS]: PropertyAlerts,
  [homePageComponents.ROOMS_OVERVIEW]: RoomsOverview,
  [homePageComponents.STAY_TOUR_VIDEO]: StayTourVideo,
  [homePageComponents.STICKY_SIDE_BY_SIDE]: StickySideBySide,
  [homePageComponents.TABBED_OVERVIEW]: TabbedOverview,
  [homePageComponents.JAPANESE_DINING]: JapaneseDining,
  [homePageComponents.UTILITY_RAIL]: UtilityRail,
  [homePageComponents.VERTICAL_POLICY_TABS]: VerticalPolicyTabs,
  [homePageComponents.VOUCHER_INFO]: VoucherInfo,
} as const;

export const componentDataMappers = {
  [homePageComponents.CELEBRITY_QUOTE]: mapHomePageDataToCelebrityQuote,
  [homePageComponents.DESCRIPTION_AMENITIES]: mapHomePageDataToDescriptionAmenities,
  [homePageComponents.EVENTS_INFO]: mapHomePageDataToEventInfo,
  [homePageComponents.FULL_WIDTH_DYNAMIC_HERO]: mapHomePageDataToFullWidthDynamicHero,
  [homePageComponents.FULL_WIDTH_IMAGE_CAROUSEL]: mapHomePageDataToFullWidthCarousel,
  [homePageComponents.FULL_WIDTH_VIDEO]: mapHomePageDataToFullWidthVideo,
  [homePageComponents.HALFANDHALF_CAROUSEL]: mapHomePageDataToHalfAndHalfCarousel,
  [homePageComponents.HONORS]: mapHomePageDataToHonors,
  [homePageComponents.HOTEL_DESCRIPTION]: mapHomePageDataToHotelDescription,
  [homePageComponents.HOTEL_POLICIES]: mapHomePageDataToHotelPolicies,
  [homePageComponents.INSPIRED_HERO]: mapHomePageDataToInspiredHero,
  [homePageComponents.LOCATION_AND_TRANSPORTATION]: mapHomePageDataToLocationAndTransportation,
  [homePageComponents.PARTNERSHIP_INFO]: mapHomePageDataToPartnershipInfo,
  [homePageComponents.PATCHWORK_GRIDS]: mapHomePageDataToPatchworkGrids,
  [homePageComponents.PROPERTY_ALERTS]: mapHomePageDataToPropertyAlerts,
  [homePageComponents.ROOMS_OVERVIEW]: mapHomePageDataToRoomsOverview,
  [homePageComponents.STAY_TOUR_VIDEO]: mapHomePageDataToStayTourVideo,
  [homePageComponents.STICKY_SIDE_BY_SIDE]: mapHomePageDataToStickySideBySide,
  [homePageComponents.TABBED_OVERVIEW]: mapHomePageDataToTabbedOverview,
  [homePageComponents.JAPANESE_DINING]: mapHomePageDataToJapaneseDiningComponent,
  [homePageComponents.UTILITY_RAIL]: mapHomePageDataToUtilityRail,
  [homePageComponents.VERTICAL_POLICY_TABS]: mapHomePageDataToVerticalPolicyTabs,
  [homePageComponents.VOUCHER_INFO]: mapHomePageDataToVoucherInfo,
} as const;
