/**
 * Parses the VTTCue text and returns the text content.
 */
export function getCueText(cue: VTTCue) {
  try {
    return new XMLSerializer().serializeToString(cue.getCueAsHTML());
  } catch {
    return cue.text;
  }
}
