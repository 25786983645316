import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';
import { getTextColorClasses } from './util/classes';

export function ControlItem({
  brandComponentTheme,
  beforeSection,
  afterSection,
  children,
  wrapperClassName,
  ...buttonProps
}: {
  beforeSection?: React.ReactNode;
  afterSection?: React.ReactNode;
} & Omit<React.ComponentProps<'button'>, 'type'> &
  Pick<VideoControlsProps, 'brandComponentTheme'> & {
    wrapperClassName?: React.ComponentProps<'li'>['className'];
  }) {
  return (
    <li className={classnames('p-1.5', wrapperClassName)}>
      <button
        {...buttonProps}
        type="button"
        className={classnames(
          'focus:z-2 relative flex w-full items-center gap-1 p-1 text-start',
          buttonProps.className
        )}
      >
        {beforeSection}
        <span
          className={classnames(
            'text-primary underline',
            getTextColorClasses({ brandComponentTheme })
          )}
        >
          {children}
        </span>
        {afterSection}
      </button>
    </li>
  );
}
