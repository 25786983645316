import type { Multimedia, VideoPlayerProps } from '@dx-ui/osc-video-player';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';

export const mapHomePageDataToFullWidthVideo = ({
  homepageData,
  layout,
}: AvailableHomePageData): TConditionalRender<VideoPlayerProps> => {
  // NOTE: Check against a `null` source due to Core+ merging the video and multimedia Couchbase types.
  // This will be fixed via https://jira.hilton.com/browse/CP-11630.
  const videos = homepageData?.videos.filter((video) => !video.source);

  if (videos?.length) {
    const multimedia = mapVideosToMultimediaList(videos);
    const brandCode = homepageData?.brandCode || '';
    const pathname = layout.homeUrlPath || '';
    const mappedPage = { brandCode, pathname };
    return mapMultimediaListToVideoProps({ multimedia, mappedPage });
  }

  return DO_NOT_RENDER;
};

const mapVideosToMultimediaList = (
  videos: NonNullable<AvailableHomePageData['homepageData']>['videos']
): Multimedia[] =>
  videos.map((video) => ({
    ...REQUIRED_FIELDS_NOT_IN_SCHEMA,
    ...video,
    multimediaMetadata: video.metadata,
    orientation: video.orientation || 'landscape',
    posterImageUrl: video.posterImageUrl || '',
    videoAutoPlay: video.autoPlay ?? false,
    videoUrl: video.url,
  }));

const REQUIRED_FIELDS_NOT_IN_SCHEMA = {
  alternativeVideoLabel: '',
  audioTrackLabel: '',
  extended: false,
  uploadDatetime: new Date().toString(),
  videoDuration: '',
  videoName: '',
};
