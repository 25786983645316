import { DO_NOT_RENDER } from '../../helpers/constants';

import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { PropertyAlerts } from '.';

export const mapHomePageDataToPropertyAlerts = ({
  ctyhocn,
}: AvailableHomePageData): TConditionalRender<PropertyAlerts> =>
  ctyhocn
    ? {
        ctyhocn,
      }
    : DO_NOT_RENDER;
