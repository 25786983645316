import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { VerticalPolicyTabs } from './VerticalPolicyTabs';

export const mapHomePageDataToVerticalPolicyTabs = ({
  homepageData,
}: AvailableHomePageData): VerticalPolicyTabs => ({
  amenities: homepageData?.amenities,
  petsInfo: homepageData?.pets,
  policy: homepageData?.policy,
  safetyInfo: homepageData?.safetyAndSecurity || {},
  ...(homepageData?.overview?.resortFeeDisclosureDesc
    ? { feesAndCharges: homepageData.overview.resortFeeDisclosureDesc }
    : {}),
});
