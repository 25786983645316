import { forwardRef } from 'react';
import cx from 'classnames';
import { BrandIcon } from './brand-icon';
import { getClassnames } from './util/button';
import type { Icon } from '@dx-ui/osc-icon';
import type { Variant } from './util/button';

export interface BrandButtonProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'> {
  /** Button label */
  label?: React.ReactNode;
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
}

export const BrandButton = forwardRef<HTMLButtonElement, BrandButtonProps>(
  ({ label, variant, iconProps, brandComponentTheme, ...buttonProps }, ref) => (
    <button
      type="button"
      {...buttonProps}
      ref={ref}
      className={cx(buttonProps.className, getClassnames({ brandComponentTheme, variant }))}
      data-testid="CTAButtonE2ETest"
    >
      <span className="flex">
        {iconProps ? <BrandIcon {...iconProps} /> : null}
        {label}
      </span>
    </button>
  )
);

BrandButton.displayName = 'BrandButton';

export default BrandButton;
