import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { UtilityRailType } from './utility-rail';

export const mapHomePageDataToUtilityRail = (data: AvailableHomePageData): UtilityRailType => ({
  contactInfo: data.homepageData?.contactInfo,
  ctyhocn: data.ctyhocn,
  hotelName: data.layout?.hotelName || '',
  registration: data.homepageData?.registration,
  tripAdvisorData: data.homepageData?.tripAdvisorLocationSummary,
});
